import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectProjectIdForIntegrationType } from "@/store/integrations/integrations-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { Tag } from "@/store/tags/tags-slice";
import {
  AnnotationEditorHeader,
  DatePicker,
  Dropdown,
  dropDownStyle,
  FaroRichTextEditor,
  NoTranslate,
  Option,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  BcfServicesIntegrationType,
  GetProjectExtensionsResponse,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import {
  CommonExternalAnnotationCreationFormProps,
  ProcoreRfiCompanionFieldId,
  ProcoreRfiCompanionFields,
} from "./annotation-props";
import { ExternalAnnotationError } from "./external-annotation-error";

type ProcoreAnnotationFormProps = Omit<
  CommonExternalAnnotationCreationFormProps,
  "stage" | "onStageChange"
> &
  ProcoreRfiCompanionFields & {
    /** callback function called when the RFI Manager has changed*/
    onRfiManagerChange?(rfiManager: string): void;
  };

/**
 * @returns a partial Procore annotation. This form does not include the title and the annotation type
 * for the complete Procore annotation form, @see ProcoreAnnotation component
 */
export function ProcoreAnnotationForm({
  allowEdition,
  externalAnnotationType,
  title,
  assignee,
  description,
  dueDate,
  status,
  priority,
  topicType,
  rfiManager,
  onTitleChange,
  onAssigneeChange,
  onDescriptionChange,
  onStatusChange,
  onPriorityChange,
  onDueDateChange,
  onTopicTypeChange,
  onRfiManagerChange,
  onError,
  setAreMandatoryFieldsFilled,
}: ProcoreAnnotationFormProps): JSX.Element {
  const { bcfServicesApiClient } = useApiClientContext();
  const emptyProjectExtensions: GetProjectExtensionsResponse = useMemo(
    () => ({
      topic_type: [],
      topic_status: [],
      topic_label: [],
      priority: [],
      users: [],
      stage: [],
      project_actions: [],
      topic_actions: [],
      comment_actions: [],
      custom_fields: [],
      companion_fields: [],
    }),
    [],
  );

  const [notEmptyQuestion, setNotEmptyQuestion] = useState(false);
  function descriptionChanged(newDescription: string): void {
    if (externalAnnotationType === BcfServicesIntegrationType.procoreRfis) {
      setNotEmptyQuestion(newDescription.trim().length > 0);
    }
    onDescriptionChange?.(newDescription);
  }
  const [projectExtensions, setProjectExtensions] =
    useState<GetProjectExtensionsResponse>(emptyProjectExtensions);

  const projectId = useAppSelector(
    selectProjectIdForIntegrationType(externalAnnotationType),
  );
  assert(projectId, "Project ID is required");

  useEffect(() => {
    // eslint-disable-next-line func-style -- FIXME
    const getProjectExtensions = async (): Promise<void> => {
      setProjectExtensions(emptyProjectExtensions);
      try {
        const extensions = await bcfServicesApiClient.getProjectExtensions(
          externalAnnotationType,
          projectId,
        );
        setProjectExtensions(extensions);
        // We are ignoring the error thrown by the BCF Services API client because it is too generic
        // and don't guide the user to solve the problem. We are showing a generic error message instead.
        // This is a temporary solution until the BCF Services API client is improved. Here is the issue
        // opened to track this improvement: https://faro01.atlassian.net/browse/CADBIM-898
        // eslint-disable-next-line unused-imports/no-unused-vars
      } catch (_) {
        onError?.({
          title: "Connection error: Unable to get the project extensions",
          error: (
            <ExternalAnnotationError
              bcfIntegrationType={externalAnnotationType}
            />
          ),
        });
      }
    };
    getProjectExtensions();
  }, [
    bcfServicesApiClient,
    emptyProjectExtensions,
    setProjectExtensions,
    externalAnnotationType,
    onError,
    projectId,
  ]);

  const assigneeOptions = useMemo(
    () =>
      projectExtensions.users.map(
        (user): Option => ({
          key: user.id,
          value: user.id,
          label: <NoTranslate>{user.name ?? user.email}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const statusOptions = useMemo(
    () =>
      projectExtensions.topic_status.map(
        (status): Option => ({
          key: status.id,
          value: status.id,
          label: <NoTranslate>{status.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const priorityOptions = useMemo(
    () =>
      projectExtensions.priority.map(
        (extensionPriority): Option => ({
          key: extensionPriority.id,
          value: extensionPriority.id,
          label: <NoTranslate>{extensionPriority.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const topicTypeOptions = useMemo(
    () =>
      projectExtensions.topic_type.map(
        (extensionTopicType): Option => ({
          key: extensionTopicType.id,
          value: extensionTopicType.id,
          label: <NoTranslate>{extensionTopicType.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const rfiManagerOptions = useMemo(
    () =>
      getProcoreRfiCompanionFieldsValues(
        projectExtensions.companion_fields,
      ).rfiManagers?.map(
        (rfiManager): Option => ({
          key: rfiManager.id,
          value: rfiManager.id,
          label: <NoTranslate>{rfiManager.name}</NoTranslate>,
        }),
      ) ?? [],
    [projectExtensions],
  );

  useEffect(() => {
    if (setAreMandatoryFieldsFilled) {
      const valid =
        !!status &&
        ((externalAnnotationType ===
          BcfServicesIntegrationType.procoreObservations &&
          !!topicType) ||
          (externalAnnotationType === BcfServicesIntegrationType.procoreRfis &&
            !!rfiManager &&
            notEmptyQuestion));
      setAreMandatoryFieldsFilled(valid);
    }
  }, [
    status,
    topicType,
    rfiManager,
    notEmptyQuestion,
    setAreMandatoryFieldsFilled,
    externalAnnotationType,
  ]);

  const { handleErrorWithToast } = useErrorHandlers();
  return (
    <>
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={false}
        onTitleChange={onTitleChange}
        disabled={!allowEdition}
      />
      <Stack
        sx={{
          // Add a scrollbar only to the main content if needed
          overflow: "auto",
          flexGrow: 1,
          gap: 3,
        }}
      >
        <FaroRichTextEditor
          initialText={description}
          dark
          fullWidth
          readOnly={!allowEdition}
          label={
            externalAnnotationType === BcfServicesIntegrationType.procoreRfis
              ? "Question"
              : "Description"
          }
          placeholder={`Insert a ${externalAnnotationType === BcfServicesIntegrationType.procoreRfis ? "question" : "description"}`}
          onError={(error) =>
            handleErrorWithToast({ title: "Error in description", error })
          }
          onChange={descriptionChanged}
          sx={{
            height: "120px",
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={6} py={0}>
            <Dropdown
              aria-label="Procore annotation status dropdown"
              label="Status"
              tag="star"
              disabled={
                !allowEdition ||
                statusOptions.length === 0 ||
                // We want to always create the RFI annotations with the status "Draft",
                // So we are disabling the status dropdown to prevent the user from changing it.
                externalAnnotationType ===
                  BcfServicesIntegrationType.procoreRfis
              }
              value={status}
              options={statusOptions}
              dark
              fullWidth
              sx={dropDownStyle}
              onChange={
                onStatusChange === undefined
                  ? undefined
                  : (event) => onStatusChange(event.target.value)
              }
              shouldCapitalize={false}
            />
          </Grid>
          {externalAnnotationType ===
            BcfServicesIntegrationType.procoreObservations && (
            <>
              <Grid item xs={6} py={0}>
                <Dropdown
                  aria-label="Procore annotation type dropdown"
                  label="Type"
                  tag="star"
                  disabled={!allowEdition || topicTypeOptions.length === 0}
                  value={topicType}
                  options={topicTypeOptions}
                  dark
                  fullWidth
                  sx={dropDownStyle}
                  onChange={
                    onTopicTypeChange === undefined
                      ? undefined
                      : (event) => onTopicTypeChange(event.target.value)
                  }
                  shouldCapitalize={false}
                />
              </Grid>
              <Grid item xs={6} py={0}>
                <Dropdown
                  aria-label="Procore annotation assignee dropdown"
                  label="Assignee"
                  disabled={!allowEdition || assigneeOptions.length === 0}
                  value={assignee}
                  options={assigneeOptions}
                  dark
                  fullWidth
                  sx={dropDownStyle}
                  onChange={
                    onAssigneeChange === undefined
                      ? undefined
                      : (event) => onAssigneeChange(event.target.value)
                  }
                  shouldCapitalize={false}
                />
              </Grid>
              <Grid item xs={6} py={0}>
                <Dropdown
                  aria-label="Procore annotation priority dropdown"
                  label="Priority"
                  disabled={!allowEdition || priorityOptions.length === 0}
                  value={priority}
                  options={priorityOptions}
                  dark
                  fullWidth
                  sx={dropDownStyle}
                  onChange={
                    onPriorityChange === undefined
                      ? undefined
                      : (event) => onPriorityChange(event.target.value)
                  }
                  shouldCapitalize={false}
                />
              </Grid>
            </>
          )}
          {externalAnnotationType ===
            BcfServicesIntegrationType.procoreRfis && (
            <Grid item xs={6} py={0}>
              <Dropdown
                aria-label="Procore annotation RFI manager dropdown"
                label="RFI Manager"
                tag="star"
                disabled={!allowEdition || rfiManagerOptions.length === 0}
                value={rfiManager}
                options={rfiManagerOptions}
                dark
                fullWidth
                sx={dropDownStyle}
                onChange={
                  onRfiManagerChange === undefined
                    ? undefined
                    : (event) => onRfiManagerChange(event.target.value)
                }
                shouldCapitalize={false}
              />
            </Grid>
          )}
          {externalAnnotationType ===
            BcfServicesIntegrationType.procoreObservations && (
            <Grid item xs={6}>
              <DatePicker
                label="Due date"
                disabled={!allowEdition}
                initialDate={dueDate ?? new Date()}
                variant="Dark"
                formSx={{ width: "100%" }}
                onChange={
                  onDueDateChange === undefined
                    ? undefined
                    : (date) => (date ? onDueDateChange(date) : {})
                }
                isClearable
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
}

/**
 * Stores all the possible values for Procore RFI Companion fields.
 */
type ProcoreRfiCompanionFieldsValues = {
  /** The values for RFI Manager field */
  rfiManagers?: Tag[];
};

/**
 * Extracts the values of the Procore RFI companion fields from the project extensions
 *
 * @param companionFields the companion fields from the project extensions
 * @returns the values of the Procore RFI companion fields
 */
function getProcoreRfiCompanionFieldsValues(
  companionFields: GetProjectExtensionsResponse["companion_fields"],
): ProcoreRfiCompanionFieldsValues {
  let rfiManagers = undefined;

  // We are not looping through the companion fields because there is only one available for the Procore RFIs
  if (
    companionFields.length > 0 &&
    companionFields[0].id === ProcoreRfiCompanionFieldId.rfiManager
  ) {
    rfiManagers = companionFields[0].enumValues?.map((value) => ({
      id: value.value,
      name: value.displayValue,
    }));
  }
  return { rfiManagers };
}
