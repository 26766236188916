import { EventType } from "@/analytics/analytics-events";
import { MiniMapOverlay } from "@/components/r3f/utils/minimap";
import { ToolsHelpBanners } from "@/components/ui/help-banners/tools-help-banners";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { SceneContextMenu } from "@/components/ui/scene-context-menu";
import { SplitScreenButton } from "@/components/ui/splitscreen-button";
import { SplitscreenLockButton } from "@/components/ui/splitscreen-lock-button";
import { SplitscreenSyncCamerasOnWaypointButton } from "@/components/ui/splitscreen-sync-cameras-on-waypoint-button";
import {
  useOverlayElements,
  useOverlayRef,
} from "@/modes/overlay-elements-context";
import { WalkOverlay } from "@/modes/walk-mode/walk-overlay";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { selectIsMinimapFullScreen } from "@/store/minimap-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectShouldSyncCamerasOnWaypoint,
  selectShouldSyncCamerasRotation,
  setCompareElementId,
  setCompareSceneFilter,
  setCompareShouldUseIntensityData,
  setShouldUseIntensityData,
  setSyncCamerasOnWaypoint,
  setSyncCamerasRotation,
  setWalkSceneFilter,
} from "@/store/modes/walk-mode-slice";
import { selectIsPanoExtractedFromData } from "@/store/project-selector";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, deactivateTool } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { SceneFilter } from "@/types/scene-filter";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import { neutral } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElementImg360,
  IElementModel3dStream,
  IElementSection,
  isIElementImg360,
  isIElementPanoInOdometryPath,
} from "@faro-lotv/ielement-types";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SplitState } from "./split-state";
import { useApplyImageAlignment } from "./use-apply-image-alignment";

/** @returns the overlay for the entire split mode */
export function SplitOverlay({
  mainScene,
  mainSceneFilter,
  mainWalkElement,
  compareScene,
  compareSceneFilter,
  compareWalkElement,
}: SplitState): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const leftScreenRef = useOverlayRef(setFirstScreen);
  const rightScreenRef = useOverlayRef(setSecondScreen);
  const isFullScreen = useAppSelector(selectIsMinimapFullScreen);
  const activeTool = useAppSelector(selectActiveTool);

  const { setMiniMap, setMiniMapCanvas } = useOverlayElements();
  const miniMapRef = useOverlayRef<HTMLDivElement>((r) => {
    setMiniMap(r);
  });
  const miniMapCanvasRef = useOverlayRef<HTMLCanvasElement>(setMiniMapCanvas);

  const dispatch = useAppDispatch();

  const areBothPointClouds =
    mainSceneFilter === SceneFilter.PointCloud &&
    compareSceneFilter === SceneFilter.PointCloud;

  // Animate entering of the right view
  const [showRight, setShowRight] = useState(false);
  useEffect(() => {
    setShowRight(true);
  }, []);

  const onMainSceneFilterChanged = useCallback(
    (type: SceneFilter) => {
      dispatch(setWalkSceneFilter(type));
      // This prevents a bug where the user has an open measurement on the CAD model, she
      // switches to point cloud, and the measurement cannot be completed anymore because
      // cross-model measurements are forbidden.
      if (activeTool === ToolName.measurement) {
        dispatch(deactivateTool());
      }
    },
    [dispatch, activeTool],
  );

  const onCompareSceneFilterChanged = useCallback(
    (type: SceneFilter) => {
      dispatch(setCompareSceneFilter(type));
      // This prevents a bug where the user has an open measurement on the CAD model, she
      // switches to point cloud, and the measurement cannot be completed anymore because
      // cross-model measurements are forbidden.
      if (activeTool === ToolName.measurement) {
        dispatch(deactivateTool());
      }
    },
    [dispatch, activeTool],
  );

  const onMainPanoTypeChanged = useCallback(
    (showIntensity: boolean, siblingPano: IElementImg360) => {
      dispatch(setShouldUseIntensityData(showIntensity));
      dispatch(setActiveElement(siblingPano.id));
    },
    [dispatch],
  );

  const onComparePanoTypeChanged = useCallback(
    (showIntensity: boolean, siblingPano: IElementImg360) => {
      dispatch(setCompareShouldUseIntensityData(showIntensity));
      dispatch(setCompareElementId(siblingPano.id));
    },
    [dispatch],
  );

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  // IElementImg360 whose orientation should be edited
  const imageElement = useMemo(() => {
    if (isIElementImg360(mainWalkElement)) return mainWalkElement;
    if (isIElementImg360(compareWalkElement)) return compareWalkElement;
  }, [mainWalkElement, compareWalkElement]);

  // true if the pano is part of a video/trajectory capture and should not be edited
  const isTrajectoryCapture =
    imageElement && isIElementPanoInOdometryPath(imageElement);

  // true if the orientation should not be edited due to the pano being extracted from other data
  const isPanoExtractedFromData = useAppSelector(
    selectIsPanoExtractedFromData(imageElement),
  );

  // Get whether the Save Pano Orientation should be enabled, and the tooltip to display on the button
  const enableSaveImage = useMemo<boolean>(() => {
    const isEnabled =
      (mainSceneFilter === SceneFilter.Pano) !==
      (compareSceneFilter === SceneFilter.Pano);

    if (
      !isEnabled ||
      !hasWritePermission ||
      !!isTrajectoryCapture ||
      isPanoExtractedFromData
    ) {
      return false;
    }

    return true;
  }, [
    hasWritePermission,
    mainSceneFilter,
    compareSceneFilter,
    isTrajectoryCapture,
    isPanoExtractedFromData,
  ]);

  const { applyImageAlignment, alignmentInProgress } = useApplyImageAlignment(
    imageElement,
    mainWalkElement,
  );

  // TODO: https://faro01.atlassian.net/browse/CADBIM-1181 will define which layer to display in the minimap
  const singleSheetForMinimap = mainScene.activeSheets[0];

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={alignmentInProgress}
      />
      {areBothPointClouds && (
        <ToolsHelpBanners
          annotationHelpBanner={{ enabled: false }}
          measureHelpBanner={{
            sx: {
              marginTop: 8,
              width: "max-content",
              display: "flex",
              alignSelf: "center",
            },
          }}
        />
      )}
      <Stack
        direction="row"
        justifyItems="stretch"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <ViewDiv
          eventDivRef={leftScreenRef}
          sx={{ width: "50%", flexGrow: 1, overflow: "hidden" }}
        >
          <SplitViewOverlay
            sceneFilter={mainSceneFilter}
            element={mainWalkElement}
            hasPanos={!!mainScene.panos.length || !!mainScene.paths.length}
            cad={mainScene.cad}
            reference={mainScene.referenceElement}
            onActiveElementChanged={(id) => dispatch(setActiveElement(id))}
            onSceneFilterChanged={onMainSceneFilterChanged}
            onPanoTypeChanged={onMainPanoTypeChanged}
            shouldHandleMeasureHelpBanner={!areBothPointClouds}
            isOtherViewUsingCad={
              compareSceneFilter === SceneFilter.Cad ||
              compareSceneFilter === SceneFilter.Overlay
            }
          />
        </ViewDiv>
        <ViewDiv
          eventDivRef={rightScreenRef}
          sx={{
            transition: "all 1s",
            width: showRight ? "50%" : "0",
            overflow: "hidden",
          }}
        >
          {!isFullScreen && (
            <SplitViewOverlay
              sceneFilter={compareSceneFilter}
              element={compareWalkElement}
              hasPanos={
                !!compareScene.panos.length || !!compareScene.paths.length
              }
              cad={compareScene.cad}
              reference={compareScene.referenceElement}
              onActiveElementChanged={(id) => dispatch(setCompareElementId(id))}
              onSceneFilterChanged={onCompareSceneFilterChanged}
              onPanoTypeChanged={onComparePanoTypeChanged}
              shouldHandleMeasureHelpBanner={!areBothPointClouds}
              isOtherViewUsingCad={
                mainSceneFilter === SceneFilter.Cad ||
                mainSceneFilter === SceneFilter.Overlay
              }
            />
          )}

          <MiniMapOverlay
            eventDivRef={miniMapRef}
            canvasRef={miniMapCanvasRef}
            activeSheetName={singleSheetForMinimap.name}
            forceMinimized={false}
          />
        </ViewDiv>
      </Stack>
      <SplitOverlayBottomButtons
        enableSaveImageOrientation={enableSaveImage}
        onSaveImageOrientation={() => {
          applyImageAlignment();
        }}
      />
      <SceneContextMenu />
    </>
  );
}

type SplitViewOverlayProps = {
  /** Active element for this View */
  element: WalkSceneActiveElement;

  /** The DataSet containing the active element */
  reference: IElementSection | undefined;

  /** True if there are panos available in the current scene */
  hasPanos: boolean;

  /** The current cad model */
  cad?: IElementModel3dStream;

  /** current filtering of the scene */
  sceneFilter: SceneFilter;

  /** Call back for when the active type is changed */
  onSceneFilterChanged(sceneFilter: SceneFilter): void;

  /** Call back for when the active element is changed */
  onActiveElementChanged(id: GUID): void;

  /** Function called when the pano type selected in the menu changed */
  onPanoTypeChanged?(showIntensity: boolean, siblingPano: IElementImg360): void;

  /** True if the measure help banner must be shown or false if it has been taken care of */
  shouldHandleMeasureHelpBanner: boolean;

  /** True if the CAD is being rendered in other split screen */
  isOtherViewUsingCad: boolean;
};

/** @returns a small wrapper to WalkOverlay to use it easily in SplitOverlay */
function SplitViewOverlay({
  element,
  reference,
  hasPanos,
  cad,
  sceneFilter,
  onActiveElementChanged,
  onSceneFilterChanged,
  onPanoTypeChanged,
  shouldHandleMeasureHelpBanner,
  isOtherViewUsingCad,
}: SplitViewOverlayProps): JSX.Element | null {
  const activeTool = useAppSelector(selectActiveTool);

  return (
    <WalkOverlay
      sx={{ p: 1 }}
      activeWalkElement={element}
      hasPanos={hasPanos}
      cad={cad}
      sceneFilter={sceneFilter}
      referenceElement={reference}
      isMeasuring={activeTool === ToolName.measurement}
      onActiveElementChanged={onActiveElementChanged}
      onSceneFilterChanged={onSceneFilterChanged}
      onPanoTypeChanged={onPanoTypeChanged}
      shouldHandleMeasureHelpBanner={shouldHandleMeasureHelpBanner}
      isOtherViewUsingCad={isOtherViewUsingCad}
    />
  );
}

/** Props for SplitOverlayBottomButtonsProps */
type SplitOverlayBottomButtonsProps = {
  /** true when the button should be enabled */
  enableSaveImageOrientation: boolean;

  /** Handler to call when the button is clicked */
  onSaveImageOrientation(): void;
};

/** @returns the buttons at the bottom of the SplitOverlay */
export function SplitOverlayBottomButtons({
  enableSaveImageOrientation,
  onSaveImageOrientation,
}: SplitOverlayBottomButtonsProps): JSX.Element {
  const dispatch = useAppDispatch();
  const shouldSyncCamerasRotation = useAppSelector(
    selectShouldSyncCamerasRotation,
  );
  const shouldSyncCamerasOnWaypoint = useAppSelector(
    selectShouldSyncCamerasOnWaypoint,
  );

  return (
    <Stack
      sx={{
        position: "absolute",
        bottom: 0,
        right: "0%",
        width: "100%",
        p: 2,
        gap: 1,
        // Prevent the container from catching events from the canvas
        pointerEvents: "none",
        // The buttons in the container still need pointer events
        "> *": {
          pointerEvents: "auto",
        },
      }}
      justifyContent="center"
      alignItems="center"
    >
      <SplitscreenLockButton
        isActive={shouldSyncCamerasRotation}
        onClick={() => {
          Analytics.track(
            shouldSyncCamerasRotation
              ? EventType.turnSplitscreenLockOff
              : EventType.turnSplitscreenLockOn,
          );

          if (!shouldSyncCamerasRotation && enableSaveImageOrientation) {
            onSaveImageOrientation();
          } else {
            dispatch(setSyncCamerasRotation(!shouldSyncCamerasRotation));
          }
        }}
      />

      <SplitscreenSyncCamerasOnWaypointButton
        isActive={shouldSyncCamerasOnWaypoint}
        onClick={() => {
          dispatch(setSyncCamerasOnWaypoint(!shouldSyncCamerasOnWaypoint));
        }}
      />
      <SplitScreenButton
        enabled
        checked
        onClick={() => {
          Analytics.track(EventType.turnSplitscreenOff);
          dispatch(changeMode("walk"));
        }}
      />
    </Stack>
  );
}
