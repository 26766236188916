import { TREE_NODE_HEIGHT } from "@/components/ui/tree/tree-node";
import { selectAllCadModels } from "@/store/cad/cad-selectors";
import { setActiveCad } from "@/store/cad/cad-slice";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import {
  selectIsControlPointLayerAlignment,
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import {
  resetWizardReferenceElementId,
  setControlPointsAlignmentEnabled,
  setWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  AnchorIcon,
  FaroText,
  FaroTooltip,
  NoTranslate,
  neutral,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericDataSession,
  IElementSection,
  IElementType,
  IElementTypeHint,
  WithHint,
  isGeoReferencedElement,
  isIElementAreaSection,
  isIElementGenericDataSession,
  isIElementGenericImgSheet,
  isIElementModel3dStream,
  isIElementPointCloudStream,
  isIElementSectionDataSession,
  isIElementWithTypeAndHint,
  isValidPose,
} from "@faro-lotv/ielement-types";
import {
  TreeData,
  selectAllIElementsOfType,
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { useCallback, useMemo, useRef, useState } from "react";
import { NodeApi, Tree, TreeApi } from "react-arborist";
import { OpenMap } from "react-arborist/dist/module/state/open-slice";
import { AlignmentWizardReferenceTreeNode } from "./align-wizard-reference-tree-node";

export enum ReferenceTreeFolderIds {
  /** string to use as an ID of 3D models folder in references selector tree of Alignment Wizard   */
  modelFolderId = "Models references root folder",

  /** string to use as an ID of clouds folder in references selector tree of Alignment Wizard   */
  cloudsFolderId = "Clouds references root folder",

  /** string to use as an ID of sheets folder in references selector tree of Alignment Wizard   */
  sheetsFolderId = "Sheets references root folder",

  /** string to use as an ID of layers folder in references selector tree of Alignment Wizard   */
  layersFolderId = "Layers references root folder",

  /** string to use as an ID of control points folder in references selector tree of Alignment Wizard   */
  controlPointsFolderId = "Control points references root folder",
}

/**
 * @returns true if the input id is a folder node in the tree = no associated IElement
 * @param id of the node to be tested
 */
export function isNodeFolderInClassWizardTree(id: string): boolean {
  return (
    id === ReferenceTreeFolderIds.cloudsFolderId ||
    id === ReferenceTreeFolderIds.sheetsFolderId ||
    id === ReferenceTreeFolderIds.modelFolderId ||
    id === ReferenceTreeFolderIds.layersFolderId
  );
}

/**
 * collect reference point clouds for given element to align
 *
 * @param elementToAlign the given element to align
 * @returns reference point clouds
 * If the given element to align is point cloud under an area section, the reference point cloud will be
 * all other cloud data session sections belonging to the same area. If the given element to align is point cloud under an area,
 * the reference point clouds will be all geo-referenced cloud data session sections.
 * Otherwise, the reference point clouds is undefined.
 */
function usePointCloudsAsReference(
  elementToAlign: IElement,
): IElementGenericDataSession[] | undefined {
  const store = useAppStore();
  const area = isIElementSectionDataSession(elementToAlign)
    ? selectAncestor(elementToAlign, isIElementAreaSection)(store.getState())
    : undefined;

  let pointClouds = undefined;
  if (area) {
    // If area is defined on previous step (eg. selected element is a cloud),
    // collect all other clouds belonging to the same area as reference (exclude elementToAlign).
    pointClouds = selectAllIElementsOfType(
      isIElementSectionDataSession,
      area.id,
    )(store.getState()).filter((cloud) => {
      const cloudStream = selectChildDepthFirst(
        cloud,
        isIElementPointCloudStream,
        3,
      )(store.getState());
      return !!cloudStream && cloud.id !== elementToAlign.id;
    });
  } else if (isIElementAreaSection(elementToAlign)) {
    // If elementToAlign is an area, collect all geo-referenced clouds as reference
    pointClouds = selectAllIElementsOfType(
      (
        element,
      ): element is WithHint<IElementSection, IElementTypeHint.dataSession> =>
        isIElementWithTypeAndHint(
          element,
          IElementType.section,
          IElementTypeHint.dataSession,
        ) && isGeoReferencedElement(element),
    )(store.getState());
  } else if (isIElementModel3dStream(elementToAlign)) {
    // If elementToAlign is a cad model, collect all point cloud datasets
    pointClouds = selectAllIElementsOfType(isIElementGenericDataSession)(
      store.getState(),
    );
  }
  return pointClouds;
}

/**
 * @returns control to select alignment reference element categorized by element type
 */
export function AlignmentWizardReferenceSelector(): JSX.Element {
  const treeRef = useRef<TreeApi<TreeData>>();
  const store = useAppStore();
  const dispatch = useAppDispatch();

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));
  assert(elementToAlign, "invalid element selected for alignment");

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const isControlPointLayerAlignment = useAppSelector(
    selectIsControlPointLayerAlignment,
  );

  const canAlignLayer = useAppSelector(selectHasFeature(Features.AlignLayer));
  const canDoControlPointsAlignment = useAppSelector(
    selectHasFeature(Features.ControlPointsAlignment),
  );

  const pointClouds = usePointCloudsAsReference(elementToAlign);

  const referenceTree = useMemo(() => {
    const referenceTree: TreeData[] = [];

    if (
      canDoControlPointsAlignment &&
      (isIElementAreaSection(elementToAlign) ||
        isIElementGenericImgSheet(elementToAlign))
    ) {
      referenceTree.push({
        id: ReferenceTreeFolderIds.controlPointsFolderId,
        label: "Control Points",
        children: null,
      });
    }

    if (canAlignLayer && isIElementGenericImgSheet(elementToAlign)) {
      const area = selectAncestor(
        elementToAlign,
        isIElementAreaSection,
      )(store.getState());

      const sheets = selectChildrenDepthFirst(
        area,
        isIElementGenericImgSheet,
      )(store.getState());

      const sheetLayers: TreeData[] = sheets
        .filter((sh) => sh.id !== elementToAlignId && isValidPose(sh.pose))
        .map((sh) => ({
          id: sh.id,
          label: sh.name,
          children: null,
          element: sh,
        }));

      if (sheetLayers.length) {
        dispatch(setWizardReferenceElementId(sheetLayers[0].id));
      } else {
        dispatch(resetWizardReferenceElementId());
      }

      referenceTree.push({
        id: ReferenceTreeFolderIds.layersFolderId,
        label: "Layers",
        children: sheetLayers.length ? sheetLayers : null,
      });

      return referenceTree;
    }

    // all 3D models in the project can be used for alignment of any sheet or cloud
    const cadModels = selectAllCadModels(store.getState());

    // sort models in alphabetical order of names
    const sortedCadModels = cadModels.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    const cads: TreeData[] = isIElementModel3dStream(elementToAlign)
      ? []
      : sortedCadModels.map((cad) => ({
          id: cad.id,
          label: cad.name,
          children: null,
          element: cad,
        }));

    referenceTree.push({
      id: ReferenceTreeFolderIds.modelFolderId,
      label: "Models",
      children: cads.length > 0 ? cads : null,
    });

    // "Sheets" folder will be filled up only in case if selected element is a cloud
    // folder will contain in that case only one area (direct ancestor of the cloud)
    const area = isIElementSectionDataSession(elementToAlign)
      ? selectAncestor(elementToAlign, isIElementAreaSection)(store.getState())
      : undefined;

    const sheet = area
      ? [
          {
            id: area.id,
            label: area.name,
            children: null,
            element: area,
          },
        ]
      : null;

    referenceTree.push({
      id: ReferenceTreeFolderIds.sheetsFolderId,
      label: "Sheets",
      children: sheet,
    });

    const clouds = pointClouds
      ? pointClouds.flatMap((cloud) => [
          {
            id: cloud.id,
            label: cloud.name,
            children: null,
            element: cloud,
            directParent: selectIElement(cloud.parentId)(store.getState()),
          },
        ])
      : [];

    referenceTree.push({
      id: ReferenceTreeFolderIds.cloudsFolderId,
      label: "Point Clouds",
      children: clouds.length > 0 ? clouds : null,
    });

    return referenceTree;
  }, [
    canAlignLayer,
    canDoControlPointsAlignment,
    dispatch,
    elementToAlign,
    elementToAlignId,
    pointClouds,
    store,
  ]);

  const [openState, setOpenState] = useState<OpenMap>({
    [referenceTree[0]?.id]: false,
  });

  const selectNode = useCallback(
    (nodes: Array<NodeApi<TreeData>>) => {
      if (nodes.length !== 1) return;
      if (isNodeFolderInClassWizardTree(nodes[0].id)) return;

      dispatch(
        setControlPointsAlignmentEnabled(
          nodes[0].id === ReferenceTreeFolderIds.controlPointsFolderId,
        ),
      );
      if (nodes[0].id === ReferenceTreeFolderIds.controlPointsFolderId) {
        return;
      }

      const element = selectIElement(nodes[0].id)(store.getState());
      assert(element, "invalid element in reference tree");

      if (isIElementModel3dStream(element)) {
        dispatch(setActiveCad(element.id));
      }
      dispatch(setWizardReferenceElementId(element.id));
    },
    [dispatch, store],
  );

  const onToggle = useCallback(() => {
    if (treeRef.current?.openState) {
      setOpenState(treeRef.current.openState);
    }
  }, [setOpenState]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <FaroText variant="heading16" sx={{ paddingLeft: 3 }}>
          Align to
        </FaroText>

        <FaroTooltip title="The reference element does not move during the alignment">
          <AnchorIcon htmlColor={neutral[400]} />
        </FaroTooltip>
      </Stack>

      {/* Never translate the tree as there is mostly customer data in there */}
      <NoTranslate>
        <Tree<TreeData>
          ref={treeRef}
          data={referenceTree}
          onSelect={selectNode}
          selection={
            isControlPointLayerAlignment
              ? ReferenceTreeFolderIds.controlPointsFolderId
              : referenceElementId
          }
          openByDefault={false}
          onToggle={onToggle}
          // Disabling dnd features for now
          disableDrag
          disableDrop
          width="100%"
          height={650}
          rowHeight={TREE_NODE_HEIGHT}
          indent={24}
          initialOpenState={openState}
        >
          {AlignmentWizardReferenceTreeNode}
        </Tree>
      </NoTranslate>
    </>
  );
}
