import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectProjectIdForIntegrationType } from "@/store/integrations/integrations-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { Tag } from "@/store/tags/tags-slice";
import {
  AnnotationEditorHeader,
  DatePicker,
  Dropdown,
  DropdownSelect,
  dropDownStyle,
  FaroChipTag,
  FaroRichTextEditor,
  FilterMenuContent,
  neutral,
  NoTranslate,
  Option,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  BcfServicesIntegrationType,
  GetProjectExtensionsResponse,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import {
  AccCompanionFieldId,
  AccCompanionFields,
  CommonExternalAnnotationCreationFormProps,
} from "./annotation-props";
import { ExternalAnnotationError } from "./external-annotation-error";

type AccCompanionFieldsValues = {
  watchers?: Tag[];
  rootCauses?: Tag[];
  publishedDefaultValue?: boolean;
};

function getAccCompanionFieldsValues(
  companionFields: GetProjectExtensionsResponse["companion_fields"],
): AccCompanionFieldsValues {
  let watchers = undefined;
  let rootCauses = undefined;
  let publishedDefaultValue = undefined;

  for (const field of companionFields) {
    switch (field.id) {
      case AccCompanionFieldId.watchers:
        watchers = field.enumValues?.map((value) => ({
          id: value.value,
          name: value.displayValue,
        }));
        break;
      case AccCompanionFieldId.rootCause:
        rootCauses = field.enumValues?.map((value) => ({
          id: value.value,
          name: value.displayValue,
        }));
        break;
      case AccCompanionFieldId.published:
        publishedDefaultValue = field.defaultValue?.[0] === "true";
        break;
    }
  }
  return { watchers, rootCauses, publishedDefaultValue };
}

type AccAnnotationFormProps = Omit<
  CommonExternalAnnotationCreationFormProps,
  "stage" | "onStageChange"
> &
  AccCompanionFields & {
    /** Callback function called when the value of the root cause has changed */
    onRootCauseChange?(rootCause: string): void;

    /** Callback function called when the value of the start date has changed */
    onStartDateChange?(startDate: Date): void;

    /** Callback function called when the selected watchers have changed */
    onSelectedWatchersChange?(selectedWatchers: Tag[]): void;

    /** Callback function called when the value of the published field has changed */
    onPublishedChange?(published: boolean): void;
  };

/**
 * @returns A form containing all the fields required for the creation of ACC annotation.
 */
export function AccAnnotationForm({
  allowEdition,
  title,
  assignee,
  description = "",
  startDate,
  dueDate,
  status,
  topicType,
  priority,
  published,
  rootCause,
  selectedWatchers,
  externalAnnotationType,
  onTitleChange,
  onAssigneeChange,
  onDescriptionChange,
  onStatusChange,
  onSelectedWatchersChange,
  onRootCauseChange,
  onStartDateChange,
  onPriorityChange,
  onPublishedChange,
  onTopicTypeChange,
  onDueDateChange,
  onError,
  setAreMandatoryFieldsFilled,
}: AccAnnotationFormProps): JSX.Element {
  const { bcfServicesApiClient } = useApiClientContext();
  const emptyProjectExtensions: GetProjectExtensionsResponse = useMemo(
    () => ({
      topic_type: [],
      topic_status: [],
      topic_label: [],
      priority: [],
      users: [],
      stage: [],
      project_actions: [],
      topic_actions: [],
      comment_actions: [],
      custom_fields: [],
      companion_fields: [],
    }),
    [],
  );

  const [projectExtensions, setProjectExtensions] =
    useState<GetProjectExtensionsResponse>(emptyProjectExtensions);

  const projectId = useAppSelector(
    selectProjectIdForIntegrationType(externalAnnotationType),
  );
  assert(projectId, "Project ID is required");

  useEffect(() => {
    // eslint-disable-next-line func-style -- FIXME
    const getProjectExtensions = async (): Promise<void> => {
      setProjectExtensions(emptyProjectExtensions);
      try {
        const extensions = await bcfServicesApiClient.getProjectExtensions(
          externalAnnotationType,
          projectId,
        );
        setProjectExtensions(extensions);
        // We are ignoring the error thrown by the BCF Services API client because it is too generic
        // and don't guide the user to solve the problem. We are showing a generic error message instead.
        // This is a temporary solution until the BCF Services API client is improved. Here is the issue
        // opened to track this improvement: https://faro01.atlassian.net/browse/CADBIM-898
        // eslint-disable-next-line unused-imports/no-unused-vars
      } catch (_) {
        onError?.({
          title: "Connection error: Unable to get the project extensions",
          error: (
            <ExternalAnnotationError
              bcfIntegrationType={externalAnnotationType}
            />
          ),
        });
      }
    };
    getProjectExtensions();
  }, [
    bcfServicesApiClient,
    emptyProjectExtensions,
    setProjectExtensions,
    externalAnnotationType,
    onError,
    projectId,
  ]);

  const assigneeOptions = useMemo(
    () =>
      projectExtensions.users.map(
        (user): Option => ({
          key: user.id,
          value: user.id,
          label: <NoTranslate>{user.name ?? user.email}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const statusOptions = useMemo(
    () =>
      projectExtensions.topic_status.map(
        (status): Option => ({
          key: status.id,
          value: status.id,
          label: <NoTranslate>{status.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const topicTypeOptions = useMemo(
    () =>
      projectExtensions.topic_type.map(
        (topicType): Option => ({
          key: topicType.id,
          value: topicType.id,
          label: <NoTranslate>{topicType.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const priorityOptions = useMemo(
    () =>
      projectExtensions.priority.map(
        (priority): Option => ({
          key: priority.id,
          value: priority.id,
          label: <NoTranslate>{priority.name}</NoTranslate>,
        }),
      ),
    [projectExtensions],
  );

  const accCompanionFieldsValues = useMemo(
    () => getAccCompanionFieldsValues(projectExtensions.companion_fields),
    [projectExtensions],
  );

  const rootCauseOptions = useMemo(
    () =>
      accCompanionFieldsValues.rootCauses?.map(
        (rootCause): Option => ({
          key: rootCause.id,
          value: rootCause.id,
          label: <NoTranslate>{rootCause.name}</NoTranslate>,
        }),
      ),
    [accCompanionFieldsValues],
  );

  const selectedWatchersOptions = useMemo(
    () =>
      selectedWatchers?.map((watcher) => ({
        key: watcher.id,
        value: watcher.id,
        label: <NoTranslate>{watcher.name}</NoTranslate>,
      })),
    [selectedWatchers],
  );

  const watchersOptions = useMemo(
    () =>
      accCompanionFieldsValues.watchers?.map(
        (watcher): Option => ({
          key: watcher.id,
          value: watcher.id,
          label: <NoTranslate>{watcher.name}</NoTranslate>,
        }),
      ),
    [accCompanionFieldsValues],
  );

  const { handleErrorWithToast } = useErrorHandlers();

  useEffect(() => {
    if (setAreMandatoryFieldsFilled) {
      const valid =
        !!status &&
        !!assignee &&
        ((externalAnnotationType ===
          BcfServicesIntegrationType.autodeskAccIssues &&
          !!topicType) ||
          (externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccRfis &&
            !!priority));
      setAreMandatoryFieldsFilled(valid);
    }
  }, [
    status,
    assignee,
    topicType,
    priority,
    externalAnnotationType,
    setAreMandatoryFieldsFilled,
  ]);

  useEffect(() => {
    // There is no UI for setting the published value,
    // Here we are just setting the default value of the project
    if (
      !!accCompanionFieldsValues.publishedDefaultValue &&
      accCompanionFieldsValues.publishedDefaultValue !== published
    ) {
      onPublishedChange?.(accCompanionFieldsValues.publishedDefaultValue);
    }
  }, [accCompanionFieldsValues, onPublishedChange, published]);

  return (
    <>
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={false}
        onTitleChange={onTitleChange}
        disabled={!allowEdition}
      />
      <Stack
        sx={{
          // Add a scrollbar only to the main content if needed
          overflow: "auto",
          flexGrow: 1,
          gap: 3,
          mx: -0.875,
          px: 0.875,
        }}
      >
        <FaroRichTextEditor
          initialText={description}
          dark
          fullWidth
          readOnly={!allowEdition}
          label="Description"
          placeholder="Insert a description"
          onError={(error) =>
            handleErrorWithToast({ title: "Error in description", error })
          }
          onChange={onDescriptionChange}
          sx={{
            height: "120px",
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={6} py={0}>
            <Dropdown
              label="Status"
              tag="star"
              disabled={
                !allowEdition ||
                statusOptions.length === 0 ||
                // We want to always create the RFI annotations with the status "Draft",
                // So we are disabling the status dropdown to prevent the user from changing it.
                externalAnnotationType ===
                  BcfServicesIntegrationType.autodeskAccRfis
              }
              value={status}
              options={statusOptions}
              dark
              fullWidth
              sx={dropDownStyle}
              onChange={(event) => onStatusChange?.(event.target.value)}
              shouldCapitalize={false}
            />
          </Grid>
          {externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccIssues && (
            <Grid item xs={6} py={0}>
              <Dropdown
                label="Type"
                tag="star"
                disabled={!allowEdition || topicTypeOptions.length === 0}
                value={topicType}
                options={topicTypeOptions}
                dark
                fullWidth
                sx={dropDownStyle}
                onChange={(event) => onTopicTypeChange?.(event.target.value)}
                shouldCapitalize={false}
              />
            </Grid>
          )}
          {externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccRfis && (
            <Grid item xs={6} py={0}>
              <Dropdown
                label="Priority"
                tag="star"
                disabled={!allowEdition || priorityOptions.length === 0}
                value={priority}
                options={priorityOptions}
                dark
                fullWidth
                sx={dropDownStyle}
                onChange={(event) => onPriorityChange?.(event.target.value)}
                shouldCapitalize={false}
              />
            </Grid>
          )}
          <Grid item xs={6} py={0}>
            <Dropdown
              label="Assignee to"
              tag="star"
              disabled={!allowEdition || assigneeOptions.length === 0}
              value={assignee}
              options={assigneeOptions}
              dark
              fullWidth
              sx={dropDownStyle}
              onChange={(event) => onAssigneeChange?.(event.target.value)}
              shouldCapitalize={false}
            />
          </Grid>
          {externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccIssues && (
            <Grid item xs={6} py={0}>
              <DropdownSelect
                dark
                disabled={
                  !allowEdition ||
                  watchersOptions === undefined ||
                  watchersOptions.length === 0
                }
                label="Watchers"
                sx={dropDownStyle}
                renderValue={() => {
                  if (!selectedWatchers?.length) {
                    return (
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          mr: 1,
                          color: neutral[500],
                        }}
                      >
                        Select watchers
                      </Typography>
                    );
                  }

                  return (
                    <FaroChipTag
                      size="small"
                      label={`${selectedWatchers.length}`}
                      dark
                    />
                  );
                }}
              >
                <FilterMenuContent
                  dark
                  selectedOptions={selectedWatchersOptions ?? []}
                  options={watchersOptions ?? []}
                  setSelectedOptions={(options) => {
                    onSelectedWatchersChange?.(
                      options.map((o) => ({
                        id: o.key,
                        name: typeof o.label === "string" ? o.label : "",
                      })),
                    );
                  }}
                />
              </DropdownSelect>
            </Grid>
          )}
          {externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccIssues && (
            <Grid item xs={6}>
              <DatePicker
                label="Start date"
                disabled={!allowEdition}
                initialDate={startDate}
                variant="Dark"
                formSx={{ width: "100%" }}
                onChange={(date) => (date ? onStartDateChange?.(date) : {})}
                isClearable
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <DatePicker
              label="Due date"
              disabled={!allowEdition}
              initialDate={dueDate}
              variant="Dark"
              formSx={{ width: "100%" }}
              onChange={(date) => (date ? onDueDateChange?.(date) : {})}
              isClearable
            />
          </Grid>
          {externalAnnotationType ===
            BcfServicesIntegrationType.autodeskAccIssues && (
            <Grid item xs={6} py={0}>
              <Dropdown
                label="Root cause"
                disabled={
                  !allowEdition ||
                  rootCauseOptions === undefined ||
                  rootCauseOptions.length === 0
                }
                value={rootCause}
                options={rootCauseOptions ?? []}
                dark
                fullWidth
                sx={dropDownStyle}
                onChange={(event) => onRootCauseChange?.(event.target.value)}
                shouldCapitalize={false}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
}
