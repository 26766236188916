// ESLint gets confused by curried selectors, making it impossible to satisfy all doc-related lints
/* eslint-disable jsdoc/check-param-names */
import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { EMPTY_ARRAY, assert, walkWithQueue } from "@faro-lotv/foundation";
import {
  GUID,
  IElementImg360,
  isIElementGenericPointCloudStream,
  isIElementImg360,
  isIElementImg360GrayScale,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  CachedWorldTransform,
  DEFAULT_TRANSFORM,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  CaptureTreeEdgeRevision,
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
  CreateOrUpdateClusterEntityParams,
  CreateOrUpdateRootEntityRequestBody,
  CreateOrUpdateScanEntityParams,
  LocalRegistrationEdge,
  RegistrationEdgeRevision,
  RegistrationEdgeType,
  RegistrationRevision,
  RevisionScanEntity,
  RevisionStatus,
  isCaptureTreeEdgeRevision,
  isDeletedRevisionStatus,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { Vector3 } from "three";
import { selectShowDiscardedConnectionLines } from "./data-preparation-view-options/data-preparation-view-options-selectors";
import { EdgesMap, EntityMap, VisibilityMap } from "./revision-slice";
import {
  EntityTransformOverrides,
  RevisionTransformCache,
} from "./revision-transform-cache";

/**
 * @param state The current application state.
 * @returns the information about the registration revision
 */
export function selectRegistrationRevision(
  state: RootState,
): RegistrationRevision | undefined {
  return state.revision.registrationRevision;
}

/**
 * @param state The current application state.
 * @returns A map from entity ID to the entity definition.
 */
export function selectRevisionEntityMap(state: RootState): EntityMap {
  return state.revision.entityMap;
}

/**
 * @param state The current application state.
 * @returns A map from entity ID to the world transform of the entity.
 */
export function selectRevisionTransformCache(
  state: RootState,
): RevisionTransformCache {
  return state.revision.transformCache;
}

/**
 * @param state The current application state.
 * @returns The map of all captureTree loaded edges.
 */
export function selectEdgesMap(state: RootState): EdgesMap {
  return state.revision.edgesMap;
}

/**
 * @param state The current application state.
 * @returns The disjunct groups of the registration if any.
 */
export function selectDisjunctGroups(state: RootState): GUID[][] {
  return state.revision.disjunctGroups;
}

/**
 * @param state The current application state.
 * @param id The ID to check for existence in the disjunct groups.
 * @returns `true` if the ID exists in any of the disjunct groups, `false` otherwise.
 */
export const selectIsIdInDisjunctGroups = createSelector(
  [selectDisjunctGroups, (state: RootState, id: GUID) => id],
  (disjunctGroups, id) => disjunctGroups.some((group) => group.includes(id)),
);

/**
 * @param state The current application state.
 * @returns The disjunct groups of the registration if any, excluding the largest group.
 * If there are multiple groups of the same max size, all groups are returned.
 * This may be the case if the algorithm would return multiple groups that have the same largest size.
 */
export const selectFilteredDisjunctGroups = createSelector(
  [selectDisjunctGroups],
  (disjunctGroups) => {
    if (disjunctGroups.length === 0) return [];

    const maxLength = Math.max(...disjunctGroups.map((group) => group.length));
    const filteredGroups = disjunctGroups.filter(
      (group) => group.length !== maxLength,
    );

    if (filteredGroups.length === disjunctGroups.length - 1) {
      return filteredGroups;
    }

    return disjunctGroups;
  },
);

/**
 * @param state The current application state.
 * @returns All loaded entities in the revision.
 */
export const selectRevisionEntities = createSelector(
  [selectRevisionEntityMap],
  // Filtering here is only necessary to make TS happy, due to the index types
  (entityMap) => Object.values(entityMap).filter(isValid),
);

/**
 * @param state The current application state.
 * @returns The disjunct groups of the registration excluding the group with
 * the oldest entity based on the createdAt property.
 * It excludes entities of particular types such us root and cluster.
 */
export const selectFilteredDisjunctGroupsExcludingOldest = createSelector(
  [selectFilteredDisjunctGroups, selectRevisionEntities],
  (disjunctGroups, entities) => {
    if (disjunctGroups.length === 0) return EMPTY_ARRAY;

    const includedEntities = entities.filter(
      (entity) =>
        entity.type !== CaptureTreeEntityType.root &&
        entity.type !== CaptureTreeEntityType.cluster,
    );

    // Find the oldest entity within the disjunct groups
    const oldestEntity = disjunctGroups
      .flat()
      .map((id) => includedEntities.find((entity) => entity.id === id))
      .filter(isValid)
      .reduce(
        (oldest, entity) =>
          entity.createdAt < oldest.createdAt ? entity : oldest,
        includedEntities[0],
      );

    // Find the group containing the oldest entity
    const groupContainingOldestEntity = disjunctGroups.find((group) =>
      group.includes(oldestEntity.id),
    );

    // Filter out the group containing the oldest entity
    const filteredGroups = disjunctGroups.filter(
      (group) => group !== groupContainingOldestEntity,
    );

    // Return the rest of the groups
    return filteredGroups;
  },
);

/**
 * @param state The current application state.
 * @param id The ID to check for existence in the filtered disjunct groups.
 * @returns `true` if the ID exists in any of the filtered disjunct groups, `false` otherwise.
 */
export const selectIsIdInFilteredDisjunctGroups = createSelector(
  [
    selectFilteredDisjunctGroupsExcludingOldest,
    (state: RootState, id: GUID) => id,
  ],
  (filteredDisjunctGroups, id) =>
    filteredDisjunctGroups.some((group) => group.includes(id)),
);

/**
 * @param state The current application state.
 * @returns The map from entity IDs to their visibility.
 *  `undefined` means visible.
 */
function selectVisibilityMap(state: RootState): VisibilityMap {
  return state.revision.visibilityMap;
}

/**
 * @param state The current application state.
 * @param entityId The ID of the entity to determine the visibility of.
 * @returns `true` if the user set the entity itself to be visible, `false` if it is set to hidden.
 *  Note that this selector doesn't consider the visibility of ancestor entities like clusters, but only the entity itself.
 */
export const selectIsEntitySetToVisible = curryAppSelector(
  createSelector(
    [selectVisibilityMap, (state, entityId: GUID) => entityId],
    (visibilityMap, entityId) => visibilityMap[entityId] !== false,
  ),
);

/**
 * @param state The current application state.
 * @param entityId The ID of the entity to determine the visibility of.
 * @returns `true` if the entity and all its ancestors are visible, `false` if it is hidden.
 */
export const selectIsEntityVisibleRecursive = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectVisibilityMap,
      (state, entityId: GUID) => entityId,
    ],
    (entityMap, visibilityMap, entityId) => {
      const ancestorIds = getAncestorIds(entityMap, entityId);

      for (const id of ancestorIds) {
        if (visibilityMap[id] === false) {
          return false;
        }
      }

      return true;
    },
  ),
);

/**
 * @returns All loaded registration edges in the revision that are not marked for removal.
 */
export const selectRevisionRegistrationEdges = createSelector(
  [selectEdgesMap, selectShowDiscardedConnectionLines],
  (edgesMap, showDiscardedConnectionLines): RegistrationEdgeRevision[] =>
    Object.values(edgesMap)
      // Filtering here is only necessary to make TS happy, due to the index types
      .filter(isValid)
      .filter((edge) =>
        // Implicitly deleted edges are always irrelevant
        // When the feature is active, explicitly deleted edges are still shown
        showDiscardedConnectionLines
          ? edge.status !== RevisionStatus.deleted
          : !isDeletedRevisionStatus(edge.status),
      ),
);

/**
 * @param state The current application state.
 * @returns All loaded registration edges, which are available on the capture tree.
 *  In particular, global edges are excluded, as they only exist in the registration report.
 */
export const selectCaptureTreeEdges = createSelector(
  [selectRevisionRegistrationEdges],
  (edges): CaptureTreeEdgeRevision[] => edges.filter(isCaptureTreeEdgeRevision),
);

/**
 * @returns `RegistrationEdgeType.local` if only local edges exist, `RegistrationEdgeType.global` if only global edges exist,
 * and `RegistrationEdgeType.slam` if no edges exist.
 */
export const selectDefaultEdgeType = createSelector(
  [selectRevisionRegistrationEdges],
  (edges): RegistrationEdgeType => {
    const edgeTypes = new Set(edges.map((edge) => edge.type));

    if (edgeTypes.has(RegistrationEdgeType.global)) {
      return RegistrationEdgeType.global;
    }

    if (edgeTypes.has(RegistrationEdgeType.local)) {
      return RegistrationEdgeType.local;
    }

    if (edgeTypes.has(RegistrationEdgeType.preReg)) {
      return RegistrationEdgeType.preReg;
    }

    return RegistrationEdgeType.slam;
  },
);

/**
 * @param state - The current state of the root reducer
 * @returns whether to use global or local results, falling back to the default edge type if not set.
 */
export const selectRegistrationEdgeType = createSelector(
  [
    (state: RootState) => state.dataPreparationViewOptions.registrationEdgeType,
    selectDefaultEdgeType,
  ],
  (registrationEdgeType, defaultEdgeType): RegistrationEdgeType =>
    registrationEdgeType ?? defaultEdgeType,
);

/**
 * @returns All loaded local registration edges in the revision.
 */
export const selectLocalEdges = createSelector(
  [selectRevisionRegistrationEdges],
  (edges): LocalRegistrationEdge[] =>
    Object.values(edges).filter(
      // Explicit type annotation won't be necessary in TS 5.5 anymore
      // See <https://www.typescriptlang.org/docs/handbook/release-notes/typescript-5-5.html>
      (
        edge: RegistrationEdgeRevision,
      ): edge is RegistrationEdgeRevision & {
        type: RegistrationEdgeType.local;
      } => edge.type === RegistrationEdgeType.local,
    ),
);

/**
 * @param state The current application state.
 * @returns A map from entity ID to the transform overrides of the entity, e.g. through user edits.
 */
export function selectEntityTransformOverrides(
  state: RootState,
): EntityTransformOverrides {
  return state.revision.transformOverrides;
}

/**
 * @param state The current application state.
 * @returns All loaded point cloud scans in the revision.
 */
export const selectRevisionScans = createSelector(
  [selectRevisionEntities],
  (revisionEntities) => revisionEntities.filter(isRevisionScanEntity),
);

/**
 * @param _state The current application state.
 * @param entityId Id of the revision entity to get the cached world transform for.
 * @returns The cached world transform of the entity.
 */
export const selectRevisionEntityWorldTransformCache = curryAppSelector(
  createSelector(
    [
      selectRevisionTransformCache,
      (_state: RootState, entityId?: GUID) => entityId,
    ],
    getEntityTransformCache,
  ),
);

/**
 * @returns whether an entities transform has been overridden directly (does not account for overrides from ancestors)
 * @param entityId the entity id to check
 */
export function selectHasEntityTransformOverride(entityId: GUID) {
  return (state: RootState) =>
    state.revision.transformOverrides[entityId] !== undefined;
}

/**
 * @returns whether at least one entity transform has been overridden
 * @param state the current application state
 */
export function selectHasSomeEntityTransformOverride(
  state: RootState,
): boolean {
  return !!Object.keys(state.revision.transformOverrides).length;
}

export type UpdatedRevisionEntities = {
  /** The parameters to update the root (if it got changed). */
  updatedRoot?: CreateOrUpdateRootEntityRequestBody;
  /** The parameters to update the clusters. */
  updatedClusters: CreateOrUpdateClusterEntityParams[];
  /** The parameters to update the scans. */
  updatedScans: CreateOrUpdateScanEntityParams[];
};

/** @returns the entities with updated transforms */
export const selectUpdatedRevisionEntities = createSelector(
  [selectRevisionEntityMap, selectEntityTransformOverrides],
  (entities, transformOverrides): UpdatedRevisionEntities => {
    let updatedRoot: CreateOrUpdateRootEntityRequestBody | undefined;
    const updatedClusters: CreateOrUpdateClusterEntityParams[] = [];
    const updatedScans: CreateOrUpdateScanEntityParams[] = [];

    for (const [id, transform] of Object.entries(transformOverrides)) {
      if (!transform) continue;

      const entity = entities[id];
      assert(entity, `Entity ${id} has an override but is not in the store`);

      switch (entity.type) {
        case CaptureTreeEntityType.root:
          updatedRoot = { ...entity, pose: transform };
          break;
        case CaptureTreeEntityType.cluster:
          updatedClusters.push({ ...entity, pose: transform });
          break;
        default:
          updatedScans.push({ ...entity, pose: transform });
      }
    }

    return { updatedRoot, updatedClusters, updatedScans };
  },
);

/**
 * @param state The current application state.
 * @returns Candidate edges which were already persisted to the capture tree.
 */
export const selectCandidateEdges = createSelector(
  [selectRevisionRegistrationEdges],
  (
    edges,
  ): Array<
    RegistrationEdgeRevision & { type: RegistrationEdgeType.candidate }
  > => edges.filter((edge) => edge.type === RegistrationEdgeType.candidate),
);

/**
 * @param entityMap All loaded entities in the revision.
 * @param entityId The ID of the entity to get the ancestor IDs for.
 * @returns The IDs of all ancestors, including the entity itself.
 */
export function getAncestorIds(entityMap: EntityMap, entityId: GUID): GUID[] {
  const ancestorIds = [];
  let currentId: GUID = entityId;

  while (currentId) {
    ancestorIds.push(currentId);

    const entity = entityMap[currentId];
    if (!entity || !entity.parentId) break;

    currentId = entity.parentId;
  }

  return ancestorIds;
}

/**
 * @param scanEntity The scan entity to get the point cloud stream for.
 * @returns The point cloud stream corresponding to the scan entity.
 */
export function selectPointCloudStreamForScanEntity(
  scanEntity?: RevisionScanEntity,
) {
  return (state: RootState) => {
    // The scan entity of the revision has the same ID as the data set IElement in the Capture Tree
    const dataSet = selectIElement(scanEntity?.id)(state);
    return selectChildDepthFirst(
      dataSet,
      isIElementGenericPointCloudStream,
    )(state);
  };
}

/**
 * @param scanEntity The scan entity to get the point cloud stream for.
 * @returns The point cloud stream corresponding to the scan entity.
 */
export function selectImg360ForScanEntity(scanEntity?: RevisionScanEntity) {
  return (state: RootState) => {
    // The scan entity of the revision has the same ID as the data set IElement in the Capture Tree
    const dataSet = selectIElement(scanEntity?.id)(state);
    return selectChildDepthFirst(
      dataSet,
      (element): element is IElementImg360 =>
        isIElementImg360(element) && !isIElementImg360GrayScale(element),
    )(state);
  };
}

/**
 * @param _state The current application state.
 * @param id The ID of the revision entity.
 * @returns The revision entity with the given ID or `undefined` if it's not loaded.
 */
export const selectRevisionEntity = curryAppSelector(
  createSelector(
    [selectRevisionEntityMap, (_state, id?: GUID) => id],
    (entityMap, id): CaptureTreeEntityRevision | undefined =>
      id ? entityMap[id] : undefined,
  ),
);

/**
 * @returns a cached map of entities to their children
 * @param state the current application state
 */
const selectRevisionEntityChildrenMap = createSelector(
  [(state: RootState) => state.revision.entityMap],
  (entityMap) => {
    const map: Record<GUID, CaptureTreeEntityRevision[] | undefined> = {};

    for (const entity of Object.values(entityMap)) {
      if (!entity?.parentId) continue;

      let childrenOfParent = map[entity.parentId];

      if (!childrenOfParent) {
        childrenOfParent = [];
        map[entity.parentId] = childrenOfParent;
      }

      childrenOfParent.push(entity);
    }

    return map;
  },
);

/**
 * @param id the entity id to get the children for
 * @returns the direct children for an entity
 */
export function selectRevisionEntityChildren(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] =>
    selectRevisionEntityChildrenMap(state)[id] ?? EMPTY_ARRAY;
}

/**
 * This selector is especially useful for when we have the id of a particular entity
 * that is marked as disjunct and we want to get the parent of it to show the proper name
 * of the cluster it belongs to.
 *
 * @param id the entity id to get the parent for
 * @returns the parent of the entity with the given ID
 */
export function selectRevisionEntityParent(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision | undefined => {
    const entity = selectRevisionEntity(id)(state);
    if (!entity) return;

    return entity.parentId
      ? selectRevisionEntity(entity.parentId)(state)
      : undefined;
  };
}

/**
 * @param id of the entity to get the descendants for
 * @returns all descendants of the entity with the given ID
 */
export function selectRevisionEntityAllDescendants(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] => {
    const children = selectRevisionEntityChildren(id)(state);

    const found: CaptureTreeEntityRevision[] = [];

    for (const child of children) {
      found.push(child);
      found.push(...selectRevisionEntityAllDescendants(child.id)(state));
    }

    return found;
  };
}

/**
 * @param state The current application state.
 * @param id The ID of the scan entity
 * @returns The scan entity with the given ID or `undefined` if it's not loaded.
 * @throws an assertion error if the entity is not a valid scan.
 */
export const selectRevisionEntityScan = curryAppSelector(
  createSelector(
    [(state, id: GUID) => selectRevisionEntity(id)(state)],
    (revisionEntity) => {
      if (!revisionEntity) return;

      assert(isRevisionScanEntity(revisionEntity));
      return revisionEntity;
    },
  ),
);

/**
 * @param _state The current application state.
 * @param entityId The ID of the entity to get the scan descendants for.
 * @returns All descendants of the entity which are scans.
 *  The scans are returned in breadth-first order.
 */
export const selectRevisionEntityDescendantScans = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectRevisionEntityChildrenMap,
      (_state, entityId?: GUID) => entityId,
    ],
    (entityMap, childrenMap, entityId?: GUID) => {
      if (!entityId) return EMPTY_ARRAY;

      const entity = entityMap[entityId];
      if (!entity) return EMPTY_ARRAY;

      const scans: RevisionScanEntity[] = [];

      walkWithQueue([entity], (current, append) => {
        if (isRevisionScanEntity(current)) {
          scans.push(current);
        }

        const children = childrenMap[current.id];
        if (children) append(...children);
      });

      return scans.length ? scans : EMPTY_ARRAY;
    },
  ),
);

/**
 * @param state The current application state.
 * @param entityId The ID of the entity to get the scan center for.
 * @returns The scan center of the entity.
 *  - If the entity is a scan, the center is the position of the scan.
 *  - If the entity is a cluster, the center is the average position of all descendant scans.
 *  - If cluster is empty, the position of the cluster itself is used.
 */
export const selectRevisionEntityScanCenter = curryAppSelector(
  createSelector(
    [
      selectRevisionTransformCache,
      (state, entityId?: GUID) =>
        selectRevisionEntityDescendantScans(entityId)(state),
      (state, entityId?: GUID) => entityId,
    ],
    (transformCache, descendantScans, entityId) => {
      if (!descendantScans.length) {
        return getEntityPosition(transformCache, entityId);
      }

      const center = new Vector3();

      for (const scan of descendantScans) {
        center.add(getEntityPosition(transformCache, scan.id));
      }

      return center.divideScalar(descendantScans.length);
    },
  ),
);

/**
 * @param state The current application state.
 * @returns A user-friendly label for the type of scans in the revision.
 */
export const selectScanTypeLabel = createSelector(
  [selectRevisionScans],
  (scans) => {
    const scanTypes = new Set(scans.map((scan) => scan.type));

    if (scanTypes.size === 1) {
      switch (Array.from(scanTypes)[0]) {
        case CaptureTreeEntityType.elsScan:
          return "Blink scans";
        case CaptureTreeEntityType.focusScan:
          return "Focus scans";
        case CaptureTreeEntityType.orbisScan:
          return "Orbis scans";
        case CaptureTreeEntityType.pCloudUploadScan:
          return "Uploaded scans";
      }
    }

    return "Scans";
  },
);

/**
 *
 * @param transformCache The cache of entity world transforms.
 * @param entityId The entity to get the cached transform for.
 * @returns The cached transform for the entity. Defaults to the identity transform.
 */
function getEntityTransformCache(
  transformCache: RevisionTransformCache,
  entityId?: GUID,
): CachedWorldTransform {
  return entityId
    ? transformCache[entityId] ?? DEFAULT_TRANSFORM
    : DEFAULT_TRANSFORM;
}

/**
 * @param transformCache The cache of entity world transforms.
 * @param entityId The entity to get the position for.
 * @returns The position of the entity with the given ID, as a vector.
 */
function getEntityPosition(
  transformCache: RevisionTransformCache,
  entityId?: GUID,
): Vector3 {
  const cachedPosition = getEntityTransformCache(
    transformCache,
    entityId,
  ).position;
  return new Vector3().fromArray(cachedPosition);
}
